<template>
  <div class="loginlog-main">
    <div class='main-title'>
      <div class="main-label">登录日志</div>
    </div>
    <el-table
    v-loading='loading'
    :data="postData"
    class="loginlog-table"
    :header-cell-style="{background:'#fafafa'}"
    >
      <el-table-column label="用户名称" prop="user_name" width="150px"></el-table-column>
      <el-table-column label="登录地址" prop="ip" ></el-table-column>
      <el-table-column label="登录地址" prop="region"> </el-table-column>
      <el-table-column label="操作系统" prop="device"> </el-table-column>
      <el-table-column label="状态" >
          <template>
            <!-- 暂时不用状态 后端容易出问题 先写死 -->
            <!-- <div>
              <span class="table-state" :style="{backgroundColor: row.status?'#1890ff':'#f11111',color: row.status?'#1890ff':'#f11111'}">
              </span>
              <span>{{row.status?'正常':'停用'}}
              </span>
            </div> -->
            <div>
              <span class="table-state">
              </span>
              <span>正常
              </span>
            </div>
          </template>
      </el-table-column>
      <el-table-column label="登录时间" prop="created_at"  align="left" width="200px"></el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getLoginLog } from '@/api/saasManage'
export default {
  data() {
    return {
      parentSelectId:[],
      loading:false,
      postData:[],
      drawer:false,
      dialog:false,
      editData:{},
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      search: '',
    };
  },
  mounted() {
     this.getData();
  },
  methods: {
    /**
     * @desc:筛选
     */
    onSearch(data){
      this.search = data;
      this.getData();
    },
    onRefresh(){
      this.search = '';
      this.getData();
    },
     /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val){
      this.parentSelectId = [];
      val.forEach(data=>{
        this.parentSelectId.push(data.id);
      })
    },
    // /**
		//  * @desc: 得到字典管理列表
		//  */
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        saas_user_name: this.search
      };
      getLoginLog(params).then(res=>{
        if(res.data && res.data.code === 200){
          this.postData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
};
</script>
<style lang='less'>
@import "../../../../css/manageMain.less";
.loginlog-main {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  .loginlog-table{
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}

</style>